<template>
  <main class="RoutesTransfer">
    <TheSubHeader
      icon="dashboard-truck-ongoing"
      title="Nova Transferência"
      type="filter"
      :actions="['back']"
      organizer-gap="1rem"
      organizer-columns="340px 1fr 1fr 160px"
    >
      <template #filter>
        <!-- Period -->
        <BaseDatePicker
          v-model="formattedDateRange"
          label="Período"
          type="datetime"
        />
        <!-- Store -->
        <BaseTreeSelect
          ref="lojista"
          v-model="form.transit_point_id"
          name="Hub"
          label="Hub"
          placeholder="Selecione"
          :select-options="optionsFields.transit_point_id"
          @input="filterStores"
        />
        <!-- Orders -->
        <BaseTreeSelect
          ref="query"
          v-model="filters.query"
          name="Pedido"
          label="Pedido"
          placeholder="Pesquisar"
          multiple
          :select-all="false"
          :enable-paste="true"
          :allow-create="true"
          :disable-branch-nodes="true"
          :enable-bipping="true"
          :tooltip="tooltip"
          @configBiping="configBiping"
          @bipping="itsBipping = true, onInputBarcodeScanned($event)"
          @not-bipping="onCodeDefault"
          @select="callbackInputBarcodeScanned"
          @deselect="callbackInputBarcodeScanned"
          @clear="callbackInputBarcodeScanned"
        />
        <!-- Filter -->
        <BaseButton
          label="Filtrar"
          class="RoutesTransfer__btn"
          theme="icon-left"
          color="primary-base"
          filled
          icon="filter"
          text-color="white"
          @click="resetSearch()"
        />
      </template>
      <template v-if="allTags.length" #tag>
        <FiltersTag v-if="allTags.length" :tags="allTags" @removeTag="removeTag" @clear="clearAllTags" />
      </template>
    </TheSubHeader>

    <section class="RoutesTransfer__orders">
      <div class="RoutesTransfer__ordersContent">
        <div class="RoutesTransfer__table">
          <!-- Table -->
          <DataTable
            v-model="form.order_ids"
            class="RoutesTransfer__table--dataTable"
            :header="ordersWithoutRoutesTransfer.data.headers"
            :table="ordersWithoutRoutesTransfer.data.content"
            @input="getTotalInvoicesValues"
          />
          <!-- Add Form -->
          <div class="RoutesTransfer__table--confirm">
            <div class="RoutesTransfer__table--information">
              <p>{{ ordersSelectedText }}</p>
              <p>{{ invoicesValues }}</p>
            </div>
            <DynamicForm
              ref="form"
              class="RoutesTransfer__form"
              :model="form"
              :blueprint="blueprint({optionsFields, disabledFields})"
              :endpoint-errors="endpointErrors"
              submit-type="json"
              @filterStores="filterStores"
              @filterDrivers="search()"
              @submit="onSubmit"
            />
          </div>
          <Pagination class="RoutesTransfer__table--pagination" @page="search()" @paginate="search()" />
        </div>
      </div>
    </section>

    <!-- Dialog -->
    <Dialog
      v-if="showMissingOrders"
      class="RoutesTransfer__dialog"
      :warning="missingOrdersText"
      :label-cancel="false"
      label-confirm="Confirmar"
      @confirm="removeMissingOrders"
    >
      <BaseList :list="missingOrders" :title="'Nome do Pedido'" :scrollbar="true" scrollbar-height="25vh" />
    </Dialog>

    <!-- BarcodeScanner -->
    <BarcodeScannerSettings
      ref="barcodeScannerSettings"
      v-bind="currentProperties"
      :stores="storesToBarcodeSettings"
      @save="handlerSaveBarcodeScannerSetting"
      @restore="handlerRestoreBarcodeScannerSetting"
      @delete="handlerDeleteBarcodeScannerSetting"
    />
  </main>
</template>

<script>
import { routes } from '@/api'
import { mapActions, mapState } from 'vuex'
import { BaseTreeSelect, BaseButton, BaseDatePicker } from '@/components/atoms'
import { DynamicForm, Pagination, DataTable, FiltersTag, Dialog, BarcodeScannerSettings, BaseList } from '@/components/molecules'
import { TheSubHeader } from '@/components/organisms'
import blueprint from './Transfer.blueprint'
import Mask from '@/plugins/mask/Mask.js'
import { BarCodeScanner } from '@/plugins/barCodeScanner/index'
import barCodeScannerMixin from '@/mixins/barCodeScanner'
import beepingSoundMixin from '@/mixins/beepingSound'

export default {
  name: 'RoutesTransfer',
  components: {
    BaseList,
    DataTable,
    BaseDatePicker,
    BaseTreeSelect,
    BaseButton,
    TheSubHeader,
    DynamicForm,
    Pagination,
    FiltersTag,
    Dialog,
    BarcodeScannerSettings,
  },
  mixins: [barCodeScannerMixin, beepingSoundMixin],
  data() {
    return {
      //Filters
      filters: {
        dateRange: {
          init: null,
          end: null,
        },
        query: [],
      },

      //Tags
      allTags: [],

      //Table
      firstSearch: true,

      //Form
      form: {
        transporter_transfer_id: null,
        transit_point_id: null,
        transit_point_delivery: null,
        driver_id: null,
        name: '',
        order_ids: [],
      },

      //Errors
      endpointErrors: {},
      invoicesValues: '',

      //Dialog
      showMissingOrders: false,
      missingOrders: [],
      missingOrdersText: '',
    }
  },
  computed: {
    ...mapState({
      //Form
      transporters: state => state.transporters.transportersTransfer,
      hubs: state => state.stores.hubs,
      //Table
      ordersWithoutRoutesTransfer: state => state.routes.ordersWithoutRoutesTransfer,
      page: state => state.pagination.page,
      paginate: state => state.pagination.paginate,
      //Helpers
      permissions: state => state.auth.permissions,
      // storesImport: state => state.stores.storesImport,
    }),

    //Options Fields
    optionsFields() {
      return {
        transporter_transfer_id: this.transporters,
        transit_point_id: this.hubs,
        transit_point_delivery: this.form.transit_point_id 
          ? this.hubs.filter(hub => hub.id !== this.form.transit_point_id.id) 
          : []
      }
    },

    //Disabled Fields
    disabledFields() {
      return {
        //Form
        transporter_transfer_id: this.permissions ? !this.permissions.orders?.trasporterFilter : true,
        transit_point_delivery: !this.form.transit_point_id,
        driver: !this.form.transporter_transfer_id || (this.form.transporter_transfer_id && !this.form.transporter_transfer_id.hasDrive),
        //Actions
        save: !this.form.order_ids.length ||
          !this.form.transit_point_id ||
          !this.form.transit_point_delivery ||
          (!this.form.transporter_transfer_id.hasDrive ? false : !this.form.driver_id) ||
          !this.form.name
      }
    },

    //Filters
    formattedDateRange: {
      get: function() {
        return [this.filters.dateRange.init, this.filters.dateRange.end]
      },
      set: function(newValue) {
        this.filters.dateRange.init = newValue[0]
        this.filters.dateRange.end = newValue[1]
      },
    },

    //Texts
    ordersSelectedText() {
      let text
      let total = this.form.order_ids.length
      if (total > 0) {
        text = `${total} pedido${total === 1 ? '' : 's'} selecionado${total === 1 ? '' : 's'}`
      } else {
        text = `Marque nas caixas de seleção ${window.innerWidth < 960 ? 'abaixo' : 'acima'} os pedidos para inserir na nova rota de transferência.`
      }
      return text
    },

  },
  mounted() {
    this.init()
  },
  methods: {    
    blueprint,
    ...mapActions({
      //Filters
      fetchHubsAsync: 'stores/fetchHubsAsync',
      //Table
      fetchOrdersWithoutRoutesTransfer: 'routes/fetchOrdersWithoutRoutesTransfer',
      setPagination: 'pagination/setPagination',
      clearPagination: 'pagination/clearPagination',
      //Form
      fetchTransportersTransfer: 'transporters/fetchTransportersTransfer',
      //Helpers
      setLoading: 'loading/setLoading',
      setError: 'error/setError',
    }),

    //[INFO] - Barcode Settings Methods are insade mixin (barCodeScannerMixin)
    ...BarCodeScanner({
      onCodeDefault: 'onCodeDefault',
      onCodeSliced: 'onCodeSliced',
      onCodeBipping: 'onCodeBipping',
      callbackBarcodeScanned: 'callbackBarcodeScanned',
      callbackInputBarcodeScanned: 'callbackInputBarcodeScanned',
    }),

    //Init
    init() {
      this.fetchHubsAsync()
      this.fetchTransportersTransfer()
      // this.resetSearch()
    },

    //Filters
    filterStores(data){
      this.filterBarcodeSettings(data)
      this.form.transit_point_delivery = null
      this.resetSearch()
      if (data.transit_point_delivery_id) {
        this.form.transit_point_delivery = data.transit_point_delivery_id
      }
    },

    //Tags
    updateTags(){
      this.allTags = [...this.filters.query]
    },

    removeTag(name, id){
      this.allTags = this.allTags.filter((tag) => tag.id.toLowerCase() !== id.toLowerCase())
      this.filters.query = this.filters.query.filter((tag) => tag.name.toLowerCase() !== name.toLowerCase())      
    },

    clearAllTags(){
      this.allTags = []
      this.filters.query = []
    },

    //Search
    async search() {
      const params = {
        obj: {
          date_hour_start: this.filters.dateRange.init,
          date_hour_end: this.filters.dateRange.end,
          query: this.filters.query,
          driver_id: this.form.driver_id ? this.form.driver_id.id : null, 
          transit_point_id: this.form.transit_point_id,
        },
        paginate: this.paginate,
        page: this.page
      }
      
      this.fetchOrdersWithoutRoutesTransfer(params)
        .then(() => {
          let ordersReturned = this.ordersWithoutRoutesTransfer.data.content.map(ordersReturned => ordersReturned.id)
          this.form.order_ids = this.form.order_ids.filter(order => ordersReturned.includes(order.id))
          this.checkIfOrdersExist()
          this.setPagination(this.ordersWithoutRoutesTransfer)
          this.firstSearch = false
        })
    
    },

    //Checkers Table
    checkIfOrdersExist() {
      let query = [...new Set(this.filters.query.map(q => q.name))]
      let ordersNumber = this.ordersWithoutRoutesTransfer.data.content.map(order => order.order_number != null ? order.order_number.toLowerCase() : order.order_number)
      let ordersInvoiceNumber = this.ordersWithoutRoutesTransfer.data.content.map(order => order.invoice_number != null ? order.invoice_number.toLowerCase() : order.invoice_number)

      this.missingOrders = query.filter(f => !ordersNumber.includes(f.toLowerCase()) && !ordersInvoiceNumber.includes(f.toLowerCase()))
      if (this.missingOrders.length > 0) {
        if (this.missingOrders.length > 1) {
          this.missingOrdersText = 'Alguns pedidos de sua pesquisa não foram encontrados no sistema com base nos filtros que você escolheu ou não estão com o status apto para serem roteirizados:'
        } else {
          this.missingOrdersText = 'Um pedido da sua pesquisa não foi encontrado no sistema com base nos filtros que você escolheu ou não está com o status apto para ser roteirizado:'
        }
        this.showMissingOrders = true
        if (!this.firstSearch && this.hasValueOnInput) this.barcodeBeepingCheck(false)
      } else {
        if(!this.firstSearch && this.hasValueOnInput) this.barcodeBeepingCheck(true)
      }
    },

    //Reset
    resetSearch() {
      this.form.order_ids = []
      this.form.driver_id = null
      this.invoicesValues = ''
      this.clearPagination(this.paginate).then(() => this.search())
    },

    getTotalInvoicesValues() {
      const invoicesData = this.form.order_ids.map((el) => el.invoiceValue)

      if (!invoicesData.length) {
        this.invoicesValues = ''
      }
      
      const hasNull = invoicesData.some((el) => {
        return el === null
      })

      if (hasNull) {
        this.invoicesValues = 'Uma ou mais notas não possuem valor descrito, por isso o total não pôde ser calculado'
        return
      } 

      const getInvoiceValues = (total, item) => {
        return total + item
      }

      const totalInvoicesValues = invoicesData.reduce(getInvoiceValues, 0)

      if (totalInvoicesValues > 0) {
        this.invoicesValues = 'Valor total da nota ' + Mask.money(totalInvoicesValues)
      } 
    },

    //Dialog
    removeMissingOrders() {
      let m = this.missingOrders
      for (let i = 0; i < m.length; i++) {
        this.removeTag(m[i], m[i])
        this.$refs.query.removeTag(m[i])
      }
      this.showMissingOrders = false
    },

    //Form
    async onSubmit(data) {
      this.setLoading(true)
      await routes.createRoute({
        ...data,
        transit_point_id: data.transit_point_id.id,
        transit_point_delivery: data.transit_point_delivery.id,
        transporter_transfer_id: data.transporter_transfer_id.id,
      }, () => this.$router.push('/routes'), (e) => this.endpointErrors = e.validation, () => this.setLoading(false))
    },
   
  },
}
</script>

<style lang="scss" scoped>
/deep/.RoutesTransfer {
  max-width: 100vw;

  /deep/.Organizer__filter{
    align-items: flex-end;
  }

  &__content,
  &__orders {
    display: grid;
    grid-template-columns: 1fr minmax(min-content, 1180px) 1fr;
    grid-template-areas: ". main .";
    grid-column-gap: 1rem;
    margin-bottom: 40px;
  }

  &__form,
  &__ordersContent {
    grid-area: main;
  }

  &__form {
    display: flex;
    align-items: flex-end;
    gap: 0 1rem;
    width: 100%;
    position: relative;

    > * {
      width: calc((100% / 5) - (150px / 5));
    }
    
    button[type=submit] {
      width: 150px;
    }

    @media #{$mobile-view} {
      gap: 0;
      width: 100%;
      flex-direction: column;
    }
  }

  &__dialog{

    .Dialog__Texts {
      width: 100%;
      margin-top: 0;

      .Dialog__Warning {
        padding: 10px 20px;
      }
    }
   
  }
  

  &__ordersContent {
    display: grid;
    grid-column-gap: 1rem;
    grid-template-areas:
      "filter"
      "table";
    @media #{$mobile-view} {
      grid-template-columns: 1fr;
      grid-template-areas:
        "filter"
        "table";
    }
  }
  &__filter {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 1rem 1rem;
    align-items: flex-end;
  }
  // &__dateRange { max-width: 380px; }
  // &__status { max-width: 200px; }
  &__break {
    flex-basis: 100%;
    height: 0;
  }
  &__drivers { width: auto; flex-grow: 1; }
  // &__btn { width: 185px }
  @media #{$mobile-view} {
    &__dateRange,
    &__status,
    &__drivers,
    &__btn { width: 100%; max-width: initial; }
  }

  &__table {
    grid-area: table;
    display: flex;
    flex-direction: column;

    &--dataTable {
      @media #{$mobile-view} {
        order: 1;
        position: relative;
      }
    }

    &--pagination {
      @media #{$mobile-view} {
        order: 2;
        position: relative;
      }
    }

    &--confirm {
      position: sticky;
      bottom: 0;
      padding: 15px 0 30px;
      background: white;
      z-index: $z-active;
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      @media #{$mobile-view} {
        padding: 0;
        margin-top: 0;
        margin-bottom: 20px;
        order: 0;
        position: relative;
      }

      p {
        font-size: $font-size-3xsmall;
        margin: 0 0 15px 0;
        padding: 0;
      }
      &:before {
        content: '';
        width: 300vw;
        display: block;
        position: absolute;
        height: 50px;
        bottom: 100%;
        left: -100vw;
        @media #{$mobile-view} {
          display: none;
        }
        pointer-events: none;
        background: linear-gradient(
          to top,
          rgba($color-neutral-stronger, 0.1) 0%,
          rgba($color-neutral-stronger, 0) 50%
        );
      }
      @media #{$mobile-view} {
        flex-direction: column;
        width: 100%;
          z-index: 4001
      }
    }

    &--information {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
  }
}
</style>